<template>
  <div>
    <h1 class="text-center lc-login-header">Coming soon...</h1>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'lc-login-login'
});
</script>

<style>
.lc-login-header {
  font-size:6em;
  font-weight:bold;
  color:#c9c9c9;
  text-shadow: #000 0 0 30px;
}

</style>