<template>
  <v-app>
    <v-app-bar
      app
      color="#b9b9b9"
      :clipped-left="!this.$vuetify.rtl"
      :clipped-right="this.$vuetify.rtl"
    >
      <div class="d-flex align-center">
        <v-img
            alt="my.liveconfig.com"
            class=""
            src="/logo.svg"
            style="height:50px; max-width:300px;"
        />

        <!--
        <v-img
          alt="my.liveconfig.com"
          class="shrink mr-2"
          contain
          src="/logo.svg"
          transition="scale-transition"
          height="50"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
        -->
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://www.liveconfig.com"
        outlined
      >
        <span class="mr-2">LiveConfig Website</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
};
</script>
