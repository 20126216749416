import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LoginBase from '../views/Login/Base.vue'
import LoginLogin from '../views/Login/Login.vue'
import PortalBase from '../views/Base.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '',
    component: LoginBase,
    children: [
      {
        path: '',
        component: LoginLogin
      }
    ]
  },
  {
    path: '/portal',
    component: PortalBase,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "portal" */ '../views/Dashboard.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
