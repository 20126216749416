<template>
  <div>
    (( Gerüst, Navigation ))
    <router-view/>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'lc-base'
});

</script>

<style scoped>

</style>